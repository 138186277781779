import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { EffectivityCitizensCharter, RemarkLevelCitizensCharter, RemarkLevelRoleMappingCitizensCharter, RemarkResolvedStatusCitizensCharter, WorklistStatusCitizensCharter } from '../entities/worklist';
import { DecimalPipe } from '@angular/common';
import { first, take, tap } from 'rxjs/operators';
import firebase from 'firebase';
import Swal from 'sweetalert2';
import { firestore } from 'firebase-admin';
import { SnackbarNotifService } from './snackbar-notif.service';
import { RequriementsService } from './requriements.service';
import { count } from 'console';
import { RestrictedAccessComponent } from '../restricted-access/restricted-access.component';

@Injectable({
  providedIn: 'root'
})
export class GovServiceService {

  showRemarksEvent : EventEmitter<any> = new EventEmitter();

  someoneistyping_remark = new BehaviorSubject<boolean>(false);

  officeUpdate = new BehaviorSubject<any>("");


  countStepwithFormula = 0;
  countStepwithPossible  = 0
  services: any = []

  citizens_charter_worklist_collection_name: string = 'Worklist-Citizens Charter'; // test collection 'CitizensCharter'
  citizens_charter_service_collection_name: string = 'Services Phase 02'; // test collection 'CitizensCharter'

  cloneSuccess:boolean =false;

  ordinals: string[] = ['th', 'st', 'nd', 'rd'];


  constructor(
    private afs : AngularFirestore,
    private auth: AngularFireAuth,
    private decimalPipe: DecimalPipe,
    private notif : SnackbarNotifService,
    private reqService: RequriementsService
    ) { }



    public resolveComment(comment_id:any,comment:any){
    comment.status = "Resolved"

    this.afs.collection("Service Remarks").doc(comment_id)
    .update( {
      status : RemarkResolvedStatusCitizensCharter.RESOLVED
    } )

  }

hideAllResolvedRemarks(service_ids:Array<any>){
  if(service_ids && service_ids.length > 0){
    service_ids.forEach(id=>{
      this.hideRemarks(id);
    })
  }
}


async hideRemarks(service_id:any,resolveOnly:boolean =false,overturn:boolean =false){

  const user_position = sessionStorage.getItem('account_subtype') || '';
  const batch = this.afs.firestore.batch();
  const remark_level = RemarkLevelRoleMappingCitizensCharter[user_position]
  const user= await this.getCurrentUser();

  let remarksFilter: QueryFn;

  if(resolveOnly){

    if(overturn){

      remarksFilter = (filter) =>
        filter.where('service_id', '==', service_id)
        .where('level','==',"ARTA")
        .where('status','==',RemarkResolvedStatusCitizensCharter.UNRESOLVED);

    }else{
      remarksFilter = (filter) =>
        filter.where('service_id', '==', service_id)
        .where('user.user_id','==',user.user_id)
        .where('status','==',RemarkResolvedStatusCitizensCharter.UNRESOLVED);
    }
    
  
  }else{
    remarksFilter = (filter) =>
      filter.where('service_id', '==', service_id);
  }

 

  this.afs.collection('Service Remarks', remarksFilter)
  .snapshotChanges()
  .pipe(tap(async (data:any)=>{

    data.forEach((item:any)=>{

        let doc:any = item.payload.doc.data();
        doc.id= item.payload.doc.id;

        let rem_ref = this.afs.firestore
              .collection('Service Remarks')
              .doc(doc.id)

        if(resolveOnly){

          batch.update(rem_ref, {
            status : RemarkResolvedStatusCitizensCharter.RESOLVED
          })

        }else{

          if(doc.status == RemarkResolvedStatusCitizensCharter.RESOLVED 
            && doc.level == remark_level){
  
              
  
              batch.update(rem_ref, {
                status : RemarkResolvedStatusCitizensCharter.DELETED
              });
          }  

        }
         
    })

    await batch.commit().then(()=>{
      Swal.close();
      this.notif.openPlainNotif("success","Remarks hide")

    }).catch((error:any)=>{
      //console.log(error)
    })


  }),take(1)).subscribe();

}


    writeReply(comment_id :any,replyMessage: any ){

      return new Promise(async (res,rej)=>{
       
              const message = replyMessage.value
          
            if(message){

              const remark_user = await this.getCurrentUser()

              const reply:any = {
                message: message,
                date: new Date(),
                user : remark_user,
                remark_id: comment_id,
                locked: false
              }

            /**
              *  this.afs.collection("Service Remarks").doc(comment_id)
              .update( {
                reply : reply
              } ).then(()=>{
                
              })
              */
            this.afs.collection("Service Remarks").doc(comment_id).collection("Remark Reply")
            .add(reply).then(()=>{
              replyMessage.value= ""
              this.notif.displayNotif("success","Reply sent")
              res(true)
            }).catch(()=>{
              res(true)
            })
              

            }else{
              res(true)
            }

    })

  
  }


  transform(n: number, year: string, keepNumber: boolean = true) {
    let v = n % 100;
    return year  + " "+  (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) + " Edition";
  }


 async getCurrentUser(){

  const test = await this.auth.currentUser;
  const user_position = sessionStorage.getItem('account_subtype') || '';


  const remark_user = {
    email: test?.email,
    name: test?.displayName,
    user_id: test?.uid,
    position: user_position
  };

  return remark_user


  }

  removeComment(id:any){
    this.afs.collection("Service Remarks").doc(id).delete()
  }

  writeComment(id:any,cntrl:any,type:any,service_id:any){


  return new Promise( async (res,rej)=>{

        
        const user_position = sessionStorage.getItem('account_subtype') || '';
        
        const message = cntrl.value
        if(message){

          const remark_user = await this.getCurrentUser()

        
        const comment:any = {
          service_id : service_id ,
          id : id,
          message: message,
          type: type,
          date: new Date(),
          level: RemarkLevelRoleMappingCitizensCharter[user_position],
          status: RemarkResolvedStatusCitizensCharter.UNRESOLVED,
          reply: "",
          user : remark_user

      }



      this.afs.collection("Service Remarks").add(comment).then(()=>{
        cntrl.value = ""
        this.someoneistyping_remark.next(false)
        this.notif.displayNotif("success","Remark saved!")
        
        res(true)
      }).catch(()=>{
        res(true)
      })


        }else{
          res(true)
        }

  })



  }


  public preProcessServiceData(service:any,remarks: any =  []) : any {

    this.countStepwithFormula = 0;
    this.countStepwithPossible = 0;

    if(service.standardRequirements) service.standardRequirements = this.preProcessStandardReq(service.standardRequirements)
  
          if(service?.situationalRequirementsArray){
            const temp_sit = service.situationalRequirementsArray
            service.situationalRequirementsArray = []
            temp_sit.forEach((situation:any)=>{
        
              service.situationalRequirementsArray.push({
                title: situation.title,
                requirement: this.preProcessStandardReq(situation.requirement),
                subRequirement: this.preprocessSubSituationalReq(situation.subRequirement? situation.subRequirement: [] )
        
              })
            })
          }
          if(service.serviceDetails.type_of_transaction){
            let transactionTypeString = ''
            if(service.serviceDetails.type_of_transaction.type_g2b == true) transactionTypeString += 'G2B (Government to Business)'
            if(service.serviceDetails.type_of_transaction.type_g2c == true) transactionTypeString += (transactionTypeString ? ', ' : '')+'G2C (Government to Citizen)'
            if(service.serviceDetails.type_of_transaction.type_g2g == true) transactionTypeString += (transactionTypeString ? ', ' : '')+'G2G (Government to Government)'
            service.serviceDetails.transactionTypeString = transactionTypeString
          }
          
          if(service.serviceDetails.operating_hours){
            if(service.serviceDetails.operating_hours == 'Others'){
              service.serviceDetails.operatingTime = service.serviceDetails.operating_hours_other
            }else{
              service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
            }

            /* let tokenizedByDash = service.serviceDetails.operating_hours.split('-')
            //if(id == '39LkWkyiZUfrvC0Lv1PE') //console.log('tokenizedByDash: ',tokenizedByDash.length)
            if(tokenizedByDash.length > 0){
              if(tokenizedByDash.length == 2){
                let tokenizedByColon1 = tokenizedByDash[0].split(':')
                //if(id == '39LkWkyiZUfrvC0Lv1PE') console.log('tokenizedByColon1: ',tokenizedByColon1.length)
                let tokenizedByColon2 = tokenizedByDash[tokenizedByDash.length-1].split(':')
                //if(id == '39LkWkyiZUfrvC0Lv1PE') console.log('tokenizedByColon2: ',tokenizedByColon2.length)
                if(tokenizedByColon1.length == 1 && tokenizedByColon2.length == 1) service.serviceDetails.operatingTime = (tokenizedByColon1[0].trim())+':00 AM - '+(tokenizedByColon2[0].trim())+':00 PM'
                else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
              }else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
            } else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours */
          }
          
          if(service.clientSteps) service.processedClientStep = this.processClientStep(service.clientSteps,remarks)
          service.countStepwithFormula = this.countStepwithFormula;
          service.countStepwithPossible = this.countStepwithPossible;


          return service
  }




  public preProcessStandardReq(standardRequirements: any) {
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'or':{
                        docItem.documentString +=formatItem.value
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
     
      })
    }
    return standardRequirements
  }

  public preprocessSubSituationalReq(subrequirement: any){

    let tempsubreq =[]

    for (var req of subrequirement){

      tempsubreq.push({
        title: req.title,
        requirement: this.preProcessStandardReq(req.requirement)
      })

    }

    return tempsubreq;

  }

  isSituational:boolean =false
  currentIndex: number = 0;

  checkifSituational(situational : boolean ,index: number): number {

    if(situational){
      if(this.isSituational){
        return this.currentIndex
      }else{
        this.isSituational = true
        this.currentIndex = index + 1

        return this.currentIndex
      }
    }else {
      this.isSituational =false
      if(this.currentIndex == 0){
        return index + 1
      }else return this.currentIndex + 1
    }

  }

  public processClientStep(clientSteps:any[],remarks : any = [], situationIndex:any= ""){
    this.countStepwithFormula =0;
    this.countStepwithPossible = 0;
    let processedClientSteps:any = []
    if(clientSteps && clientSteps.length > 0){
      clientSteps.map((clientStep,index)=>{

        if(clientStep.situational){

          //console.log("Situational Step",clientStep)

          let situationalStep:any = {
            clientStep : this.processClientStep(clientStep.clientStep,remarks ,index + 1),
            situational_step_title : (index + 1)+ '.' + clientStep.situational_step_title,
            step_title : clientStep.situational_step_title.trim() ? true : false,
            situational:  clientStep.situational

          }
          
          processedClientSteps.push(situationalStep)

        }else{
          if (clientStep.info?.list_of_formula_fees_arr.length > 0) {
            this.countStepwithFormula++;
          }
          if(clientStep.info?.list_of_possible_fees_arr.length > 0){
            this.countStepwithPossible++;
          }
          if(clientStep.agency_actions && clientStep.agency_actions.length > 0){
            
            clientStep.agency_actions.map((agencyAction:any,index2:number)=>{
              let clientStepRow = {
                step_remarks: remarks.filter( (step:any) => step.id == clientStep.info.step_Id) ,
                action_remarks: remarks.filter( (action:any) => action.id == agencyAction.action_Id) ,
                step_Id:clientStep.info.step_Id,
                action_Id: agencyAction.action_Id,
                clientStepName: situationIndex? situationIndex + "." + this.reqService.printToLetter(index + 1) + '.' + clientStep.info.title :  index2 == 0 ? index + 1 +'. '+clientStep.info.title: '', //(clientStep.info.is_situational_step? index :  index+1)
                agencyActions: situationIndex? situationIndex + "." + this.reqService.printToLetter(index + 1) + '.' + (index2 + 1) +'. '+agencyAction.title :  index + 1 +'.'+(index2+1)+'. '+agencyAction.title,
                fees: index2 == 0 ? this.getClientStepFees(clientStep.info) : '',
                location: clientStep.info.location,
                processingTime:'',
                standard_fees: clientStep.info.standard_fees,
                possibleFeesArr: clientStep.info.list_of_possible_fees_arr,
                formulaFeesArr: clientStep.info.list_of_formula_fees_arr,
                notes: clientStep.info.notes,
                personResponsible:agencyAction.persons_responsible,
                //situational step is removed from client step situational title is set as a main title for any underlying situational steps
                isSituationalStep: clientStep.info.is_situational_step,
                situation: clientStep.info.is_situational_step ? clientStep.info.situation : '',
                
                // borderBottom: (index2 < (clientStep.agency_actions.length - 1)) ? false : true,
                // borderTop: (index2 > 0 ) ? false : true,
                rowSpan: (clientStep.agency_actions.length > 1) ? (index2 == 0) ? clientStep.agency_actions.length : 0 : 1 //(index == 0) ? (clientStep.agency_actions.length > 1) ? clientStep.agency_actions.length : 0 : 1
              }
  
              // check if AA L > 1
                // T: check index 0
                  // T: AA L
                  // F: 0
                // F: 1
  
              let processingTime = '';
              if(agencyAction.processing_time_days){
                const days = Number(agencyAction.processing_time_days);
                if(days && !isNaN(days) && days > 0) processingTime += agencyAction.processing_time_days+' day/s'
              }
  
              if(agencyAction.processing_time_hours){
                const hours = Number(agencyAction.processing_time_hours);
                if(hours && !isNaN(hours) && hours > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_hours+' hour/s'
              }
  
              if(agencyAction.processing_time_minutes){
                const mins = Number(agencyAction.processing_time_minutes);
                if(mins && !isNaN(mins) && mins > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_minutes+' minute/s'
              }
              clientStepRow.processingTime = processingTime
              processedClientSteps.push(clientStepRow)
            })
          }else{
            processedClientSteps.push({
              clientStepName: clientStep.info.title,
              agencyActions:'',
              fees: this.getClientStepFees(clientStep.info),
              processingTime:'',
              personResponsible:'',
              borderBottom: true,
            })
          }
        }
       
      })
      return processedClientSteps
      // for(let clientStep of clientSteps){
      //   let clientStep = {
      //     clientStepName:clientStep,
      //     agencyActions:'',
      //     fees:'',
      //     processingTime:'',
      //     personResponsible:''
      //   }

      // }
    }

    return processedClientSteps
  }

  public getClientStepFees(clientStepData:any){

    let fees = '';


    if(clientStepData.standard_fees|| clientStepData.list_of_possible_fees || clientStepData.list_of_formula_fees){

      if(clientStepData.standard_fees){
        let sameCurrency:any[]=[]
        fees += ( fees ? '<br><br>' : '') + '<strong>Standard Fees </strong><br>Breakdown: <br>';
        clientStepData.standard_fees_arr.map((standard:any)=>{
            
            fees += standard.fee_type + ': ' + standard.currency + ' ' + standard.amount + '<br>';
        })

        fees += (fees ? '<hr>': '')

        fees += 'Total: <br>';
      }

      if(clientStepData.standard_fees){
        clientStepData.fees.map((standard:any)=>{
          fees += standard.currency + ' ' + standard.amount + '<br>'
        })
      }

      if(clientStepData.list_of_possible_fees){
        fees += ( fees ? '<br><br>' : '') + '<strong>Possible Fees </strong><br>Breakdown: <br>';
        clientStepData.list_of_possible_fees_arr.map((possible:any)=>{
            fees += possible.fee_type + ': ' + possible.currency + ' ' + possible.amount + '<br>';
        })
      }

      if(clientStepData.list_of_formula_fees){
        fees += ( fees ? '<br><br>' : '') + '<strong>Formula Fees </strong><br>Breakdown: <br>';
        clientStepData.list_of_formula_fees_arr.map((formula:any)=>{
            fees += formula.fee_type 
        })
      }

      return fees

    }

    return 'None'

    /* let fees = ''
    if(clientStepData.standard_fees == true || clientStepData.list_of_possible_fees_or_formulas == true){
      if(clientStepData.standard_fees == true){
        fees += clientStepData.fees+' PHP'//return clientStepData.fees+' php'
        if(clientStepData.fees_arr && clientStepData.fees_arr.length > 0){
          fees += (fees ? '<br><br>' : '')+'Breakdown:';
          clientStepData.fees_arr.map((fee_data:any)=>{
            fees += (fees ? '<br>' : '')+fee_data.fee_type+':'
            if(fee_data.amount) fees += (fees ? ' ' : '')+fee_data.amount 
          })
        }
      } //fees += clientStepData.fees+' php'//return clientStepData.fees+' php'
      else if(clientStepData.list_of_possible_fees_or_formulas == true && clientStepData.list_of_fees_arr && clientStepData.list_of_fees_arr.length > 0) {
        clientStepData.list_of_fees_arr.map((list_of_fees_data:any)=>{
          fees+=(fees ? '<br><br>' : '')+list_of_fees_data.fee_type+':'
          if(list_of_fees_data.list_fee_desc) fees+=(fees ? '<br>' : '')+list_of_fees_data.list_fee_desc
        })
      }//fees += (fees ? '<br>' : '')+clientStepData.list_of_fees_val//return clientStepData.list_of_fees_val

      return fees
    }
    // else if(clientStepData.is_situational_step) return clientStepData.fees+' php'
    return 'None' */
  }

  prevID:any=""

  scrollTo(serviceId:any){
    
    if (serviceId) {
      // Reset the color of the previously scrolled-to card
      if (this.prevID) {
        const prevDoc = document.getElementById(this.prevID);
        if (prevDoc) {
          prevDoc.style.backgroundColor = 'transparent'; // Reset color to its default
        }
      }
  
      // Set the color and scroll to the new card
      const doc = document.getElementById(serviceId);
      if (doc) {
        doc.style.backgroundColor = '#C2CFD8';
        doc.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
  
        // Update the previous ID to the current one
        this.prevID = serviceId;
      }
    }
  }

  getTotalofSameCurrency(array:any[]){

    const sum = array.reduce((acc,cur)=>{
      const found = acc.find((val:any) => val.currency === cur.currency)
      if(found) found.amount += Number(cur.amount)
      else acc.push({...cur,amount: Number(cur.amount)})

      return acc
    },[])

    return sum

  }

  // Add handling for general fees + each situational fee
  computeTotalFees(client_step_arr:any, situationalStep?:any){

    
    let counter:any[]= []
    
    counter.push(...this.computeFees(client_step_arr))

    if(situationalStep?.info?.standard_fees){

      counter.push(...situationalStep.info.fees)

    }

    //if(situationalStep?.info?.is_situational_step && situationalStep?.info?.standard_fees) {
      //counter+= situationalStep.info.fees
    //}
  
    return this.getTotalofSameCurrency(counter) 
  }

  computeFees(client_step_arr:any){

    let counter:any[] = []
    
    client_step_arr.forEach((data:any)=>{
      if(data?.situational){
        counter.push(...this.computeFees(data.clientStep))
      }else{
        if(data?.info?.standard_fees && !data?.info?.is_situational_step) {
          counter.push(...data.info.fees)
        }
      }
    })
  
    return counter 

  }

  computeTotalProcessingTime(client_step_arr: any, situational_step?: any) {
    let totalProcessingTimeMinutes = 0;

    totalProcessingTimeMinutes = this.computeStepProcessingTime(client_step_arr)

    if(situational_step){
      totalProcessingTimeMinutes += this.computeClientStepTotalProcessingTime(situational_step)
    }

    return {
      totalProcessingTimeMinutes: totalProcessingTimeMinutes,
      totalProcessingTimeStr: this.getTotalProcessingTimeString(totalProcessingTimeMinutes)
    }
  }

  computeStepProcessingTime(client_step_arr:any){

    let totalProcessingTimeMinutes = 0;
  
    client_step_arr.forEach((v: any) => {
      // Include only general/non-situational steps for total processing time
      // Adding situational processing time will be handled per individual step
      if(!v.situational) {
        totalProcessingTimeMinutes += this.computeClientStepTotalProcessingTime(v);
      }
      /**removed adding of situationaal processing time to standard processing time total
       * 
       * else{
        totalProcessingTimeMinutes += this.computeStepProcessingTime(v.clientStep)
      }
       */
    });

    return totalProcessingTimeMinutes

  }

  getTotalProcessingTimeString(totalMinutes : number){

    const getHoursFromMin = Math.floor(totalMinutes/60);
    const getRemainingMinutes = totalMinutes % 60;
    const getRemainingHours = getHoursFromMin % this.maxHours;
    const getDays = Math.floor(getHoursFromMin/this.maxHours);    

    const minutesStr = getRemainingMinutes > 0 ? `${this.decimalPipe.transform(getRemainingMinutes,'1.0-2')} minute/s` : ``;
    const hoursStr = getRemainingHours > 0 ? `${this.decimalPipe.transform(getRemainingHours,'1.0-2')} hour/s` : ``;
    const daysStr = getDays > 0 ? `${this.decimalPipe.transform(getDays,'1.0-2')} day/s` : ``;

    let returnTotalProcessingTimeStr = `${daysStr}${daysStr && hoursStr ? ", " : ""}${hoursStr}${(daysStr || hoursStr) && minutesStr ? ", " : ""}${minutesStr}`;
    

    return returnTotalProcessingTimeStr

  }

  maxHours : number = 8

  private computeClientStepTotalProcessingTime(clientStep: any) {
    let retTotalProcessingTimeMinutes = 0;
    clientStep.agency_actions.forEach((v: any) => {
      // Remove currencyPipe-formatted commas because day input can have no limits
      if(!isNaN(v.processing_time_days.replace(/\D/g, ''))){
        // 8 working hours/day rather than the usual 24 hours/day
        retTotalProcessingTimeMinutes += +v.processing_time_days.replace(/\D/g, '') * this.maxHours * 60;
      }

      if(!isNaN(v.processing_time_hours)){
        retTotalProcessingTimeMinutes += +v.processing_time_hours * 60;
      }

      if(!isNaN(v.processing_time_minutes)){
        retTotalProcessingTimeMinutes += +v.processing_time_minutes;
      }
    })

    return retTotalProcessingTimeMinutes;
  }

  async saveCloneService(service_data:any, worklist_data:any){
    const batch = this.afs.firestore.batch();
    const user = await this.auth.currentUser;
    let fromOfficer = user?.displayName;

    service_data.parentServiceId = worklist_data.documentId
    service_data.serviceDetails.service_name = service_data.serviceDetails.service_name + " - Copy"
    let service_ref =this.afs.firestore.collection(this.citizens_charter_service_collection_name).doc();
    batch.set(service_ref,await service_data)

    let worklist_ref = this.afs.firestore.collection(this.citizens_charter_worklist_collection_name).doc();
    let worklistJSON: any = {
      dateGenerated: firebase.firestore.Timestamp.now(),
      fromAgency: service_data.fromAgencyId,
      fromOfficer: fromOfficer,
      status: WorklistStatusCitizensCharter.DRAFT,
      documentFile: '',
      documentId: service_ref.id,
      process_owner: service_data.serviceDetails.process_owner,
      category: service_data.serviceDetails.service_category ,
      documentTitle: service_data.serviceDetails.service_name + " - Copy",
      dueDate: firebase.firestore.Timestamp.now(),
      effectivity: EffectivityCitizensCharter.NEW,
      serviceCompleteness:  worklist_data.serviceCompleteness
    }

    batch.set(worklist_ref,await worklistJSON);

    await batch.commit().then(()=>{
      this.notif.displayNotif('success',"Service has been cloned!")
      this.cloneSuccess =true;
    })

    

  }

  getServiceDatathenSave(worklist_data:any){
    this.afs.collection("Services Phase 02").doc(worklist_data.documentId)
    .snapshotChanges().pipe(first())
    .subscribe({
      next : (info) => {
          if(info.payload.exists){
            let service:any = info.payload.data();
            service.id = info.payload.id;
            this.saveCloneService(service,worklist_data)
          }
      },
      error: (err)=>{
        //console.log("Error",err)
      }
    })
  }

  cloneService(worklist_data:any){

    if(worklist_data){

      Swal.fire({
        title: 'Clone this Service!',
        text: 'Are you sure?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Clone Service'
      }).then((result) => {
        let bypass:boolean =true
        if (result.isConfirmed) this.getServiceDatathenSave(worklist_data) ;
        else return
      })

    }
  }  

  
}


