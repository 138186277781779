import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AgencyRegisterMemberModalComponent } from '../agency-register-member-modal/agency-register-member-modal.component';
import { accountRole } from '../agency-register-member/agency-register-member.component';
import { PBRIS, ARTEMIS } from 'src/app/entities/constant';

@Component({
  selector: 'app-agency-members-control',
  templateUrl: './agency-members-control.component.html',
  styleUrls: ['./agency-members-control.component.scss']
})
export class AgencyMembersControlComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  currentSystem:any = sessionStorage.getItem('currentSystem');

  pbris = PBRIS
  artemis = ARTEMIS

  currentUser:any;
  currentAgency:any;
  currentMotherAgency:any;
  agencyTitle: any = "";

  agencyId = this.route.snapshot.params.agencyId

  loader: boolean = false;
  
  currentUserId: any;

  isAgencyAdmin: boolean = false; 
  isAdmin: boolean = false;

  chosenMember:any;

  list_of_role  = [accountRole.Default,accountRole.Lead_Encoder,accountRole.Lead_Verifier]
 
  memberDataSource:any = []
  memberDataSourceColumns: string[] = ["name", "email","account_subtype"]
  allMembers: any[] = []
  allMembersS: any[] = []

  filtersForm = new FormGroup({
    search: new FormControl(''),
  })

  agencyEditForm:boolean = true;
  agencyForm = this.fb.group({
    account_subtype : new FormControl(''),
    official_designation : new FormControl(''),
    subagency : new FormControl(''),
    role: new FormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    public fb: FormBuilder,
    private dialogRef: MatDialog,
    ) { }

  ngOnInit(): void {
    this.checkUser();
    this.loadAgency(this.agencyId)
    this.loadAgencyMembers(this.agencyId)
  }

  artaCheck(){
    if(this.isAgencyAdmin && this.chosenMember.credentials.account_subtype){
      return this.chosenMember.credentials.account_subtype.trim().toLowerCase().includes('arta')
    }
    return false
  }
  filterRow(value: any){
    this.list_of_role = []

    switch(value){

      case GovernmentAccountSubtype.AGENCY_ENC: 

           this.list_of_role = [accountRole.Default,accountRole.Lead_Encoder]

           break;
      case GovernmentAccountSubtype.AGENCY_VER:
          this.list_of_role = [accountRole.Default,accountRole.Lead_Verifier]
          break;
      default: 
          this.list_of_role = [accountRole.Default]
    }

    return this.list_of_role


  }
  async checkUser(){
    if(await this.auth.user){
      switch(sessionStorage.getItem("user_type")){
        case 'arta':{
          this.isAdmin = sessionStorage.getItem("arta_admin") === "true" ? true : false
          this.auth.onAuthStateChanged((user:any)=>{
            if(user.uid){
              this.afs.collection('Users').doc(user.uid).get().toPromise()
              .then((data)=>{
                if(data.exists){
                  const item:any = data.data()
                  item.id = user.uid
                  this.currentUser = item
                  this.currentUserId = user.uid
                }
              })
            }
          })
          break;
        }
        case 'agency':{
          this.auth.onAuthStateChanged((user:any)=>{
            if(user.uid){
              this.afs.collection('Users').doc(user.uid).get().toPromise()
              .then((data)=>{
                if(data.exists){
                  const item:any = data.data()
                  item.id = user.uid
                  this.currentUser = item
                  this.currentUserId = user.uid
                  if (item.government.agency !== this.agencyId) {
                    this.router.navigate([''])
                    return;
                  }
                  if(item.credentials.account_subtype.trim().toLowerCase().includes("government") 
                    && item.credentials.account_subtype.trim().toLowerCase().includes("it/admin") 
                    && item.government.agency === this.agencyId){
                      this.isAgencyAdmin = true
                  }
                  else{
                    this.router.navigate(['/artemis/agency/view/'+this.agencyId])
                  }
                }
              })
            }
          })
          break
        }
        default:this.toHome()
      }

      // if(sessionStorage.getItem("user_type") === "arta") {
      //   this.isAdmin = sessionStorage.getItem("arta_admin") === "true" ? true : false
      // }
      // else if ( sessionStorage.getItem("user_type") === "agency"){
      //   // this.afs.collection('Users').doc()
      //   this.auth.onAuthStateChanged((user:any)=>{
      //     if(user.uid){
      //       this.afs.collection('Users').doc(user.uid).get().toPromise()
      //       .then((data)=>{
      //         if(data.exists){
      //           const item:any = data.data()
      //           item.id = user.uid
      //           this.currentUser = item
      //           this.currentUserId = user.uid
      //           if(item.credentials.account_subtype.trim().toLowerCase().includes("government") 
      //             && item.credentials.account_subtype.trim().toLowerCase().includes("it/admin") 
      //             && item.government.agency === this.agencyId){
      //               this.isAgencyAdmin = true
      //           }
      //           else{
      //             this.router.navigate(['/artemis/agency/view/'+this.agencyId])
      //           }
      //         }
      //       })
            
      //     //   .snapshotChanges()
      //     //   .subscribe(data=>{
      //     //     const item:any = data.payload.data()
      //     //     item.id = user.uid
      //     //     this.currentUser = item
      //     //     this.currentUserId = user.uid
      //     //     if(item.credentials.account_subtype.trim().toLowerCase().includes("government") 
      //     //       && item.credentials.account_subtype.trim().toLowerCase().includes("it/admin") 
      //     //       && item.government.agency === this.agencyId){
      //     //         this.isAgencyAdmin = true
      //     //     }
      //     //     else{
      //     //       this.router.navigate(['/artemis/agency/view/'+this.agencyId])
      //     //     }
      //     //   })
      //     }
      //   })
      // }
      // else{
      //   this.toHome();
      // }
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  loadAgency(agencyId:any){
    this.loader = true
    this.afs.collection('Agency').doc(agencyId).snapshotChanges()
    .subscribe(info=>{
      const agency:any = info.payload.data()
      agency.id = info.payload.id;
      this.agencyTitle = 
        (agency.agencyDetails) 
          ? ((agency.agencyDetails.agencyName) 
              ?(agency.agencyDetails.agencyName) 
              : ((agency.name) ? (agency.name) : "") ) 
          : ((agency.name) ? (agency.name) : "")
      this.currentAgency = agency
    })
  }

  loadAgencyMembers(agencyId:any){
    this.loader = true
    this.afs.collection('Users',filter=>filter.where('government.agency','==',agencyId)).snapshotChanges()
    .subscribe({
      next:(info)=>{
        let tempArr: any = []
        info.forEach(data=>{
          const member: any = data.payload.doc.data()
          member.id = data.payload.doc.id
          tempArr.push(member)
        })
        this.allMembers = tempArr
        this.memberDataSource = new MatTableDataSource<any>(tempArr)
        this.resetSortPage()
        this.loader = false
      },
      error:(err)=>{
        console.error("Error : Failed to retreive agency members ",err)
        this.loader = false
      }
    })
    // .ref
    // .where('government.agency','==',agencyId)
    
    // .get()
    // .then(info=>{

    //   let tempArr: any = []
    //   info.forEach(data=>{
    //     const member: any = data.data()
    //     member.id = data.id
    //     tempArr.push(member)
    //   })
    //   this.allMembers = tempArr
    //   this.memberDataSource = new MatTableDataSource<any>(tempArr)
    //   this.resetSortPage()
    //   this.loader = false
    //   // sort & paginator
    // })
    // .catch(err=>{
    //   console.error("Error : Failed to retreive agency members ",err)
    //   this.loader = false
    // })
  }
  
  memberChosen(member:any){
    this.chosenMember = member
    this.patchValue('agency')
  }

  patchValue(editName:any){
    if(editName === 'agency'){
      if(this.chosenMember.credentials || this.chosenMember.government ){
        if(this.chosenMember.credentials){
          this.agencyForm.patchValue({
            account_subtype: (this.chosenMember.credentials.account_subtype) ? (this.chosenMember.credentials.account_subtype) : ""
          })

          this.agencyForm.patchValue({
            role : this.chosenMember.credentials.role ? this.chosenMember.credentials.role : 'Basic'
          })
        }
        if(this.chosenMember.government){
          this.agencyForm.patchValue({
            official_designation: (this.chosenMember.government.official_designation) ? (this.chosenMember.government.official_designation) : "",
            subagency: (this.chosenMember.government.subagency) ? (this.chosenMember.government.subagency) : "",
          })
        }
      }
      else {
          this.agencyForm.reset()
      }
    }
  }

  resetSortPage(){
    this.memberDataSource.paginator = this.paginator
    this.memberDataSource.sort = this.sort
    this.settingFilter()
    this.settingSorter()
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    // console.log(filterValue)
    this.memberDataSource.filter = filterValue.trim().toLowerCase()
    // this.resetSortPage()
    
    if (this.memberDataSource.paginator) {
        this.memberDataSource.paginator.firstPage();
    }
  }

  settingFilter(){
    this.memberDataSource.filterPredicate = (data:any, filter:string): boolean=>{
        return data.personal.first_name.toLowerCase().includes(filter.toLowerCase())
        || data.personal.last_name.toLowerCase().includes(filter.toLowerCase())
        || (data.personal.first_name+" "+data.personal.last_name).toLowerCase().includes(filter.toLowerCase())
        || data.credentials.email.toLowerCase().includes(filter.toLowerCase())
        || data.credentials.account_subtype.toLowerCase().includes(filter.toLowerCase())
    }
  }

  settingSorter(){
    this.memberDataSource.sortingDataAccessor = (data:any, property:any) =>{
      if(data[property] == null || data[property] == ""){
        if(this.sort.direction == "desc") return '\u0000'+'\u0000'+'\u0000';
      return '\uFFFF'+'\uFFFF'+'\uFFFF';
      }
      return data[property]
    }
  }
  
  sortChange(sort: Sort){
    let data = this.allMembers.slice()
    if(!sort.active || sort.direction === ''){
        // this.allMembersS = data
        return;
    }
    data = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
          case 'first_name':
          return this.compare(a.personal.first_name.trim().toLowerCase(), b.personal.first_name.trim().toLowerCase(), isAsc)
          case 'email':
          return this.compare(a.credentials.email.trim().toLowerCase(), b.credentials.email.trim().toLowerCase(), isAsc)
          case 'account_subtype':
          return this.compare(a.credentials.account_subtype.trim().toLowerCase(), b.credentials.account_subtype.trim().toLowerCase(), isAsc)
          default:
          return 0
        }
    })
    this.memberDataSource = new MatTableDataSource<any>(data)
    this.resetSortPage()
  }

  submitUpdate(editName:any){
    this.loader = true;
    if(editName === 'agency'){
      this.validateField('agency')
      //console.log(this.agencyForm.value)
      // if(false){
      if(this.agencyForm.valid && (this.isAdmin || this.isAgencyAdmin)){
        const agencyData = this.agencyForm.value
        this.afs.collection('Users').doc(this.chosenMember.id).update({
          "credentials.account_subtype": agencyData.account_subtype,
          "government.official_designation": agencyData.official_designation,
          "government.subagency": agencyData.subagency,
          "credentials.role" : agencyData.role
        })
        .then(()=>{
          //console.log("succesfullt updated user");
          this.chosenMember.credentials.account_subtype = agencyData.account_subtype;
          this.chosenMember.government.official_designation = agencyData.official_designation;
          this.chosenMember.government.subagency = agencyData.subagency;
          this.chosenMember.credentials.role = agencyData.role

          const ctr = this.allMembers.map(res=>res.id).indexOf(this.chosenMember.id)
          if(ctr>=0){
            this.allMembers[ctr].credentials.account_subtype = this.chosenMember.credentials.account_subtype;
            this.allMembers[ctr].government.official_designation = this.chosenMember.government.official_designation;
            this.allMembers[ctr].government.subagency = this.chosenMember.government.subagency;
            this.allMembers[ctr].credentials.role = this.chosenMember.credentials.role
            this.memberDataSource = new MatTableDataSource<any>(this.allMembers);
            this.resetSortPage()
          }
          //sort & page
          this.updateToggle('agency','cancel')
          this.loader = false;
        })
        .catch(err=>{
          console.error("Error updating member ",err)
          this.loader = false;
        })
      }
      else{
        console.error("Form Invalid")
        this.loader = false;
      }
    }
  }

  toHome(){
    this.router.navigate(['/'])
  }

  universalOption(controlName:any){
    switch(controlName){
      case 'govsubtype':{
        let account_subtype:any = []
        switch(this.chosenMember.credentials.account_type){
          case 'Government':{
            account_subtype.push(GovernmentAccountSubtype.AGENCY_ENC);
            account_subtype.push(GovernmentAccountSubtype.AGENCY_VER);
            account_subtype.push(GovernmentAccountSubtype.AGENCY_DIR);
            account_subtype.push(GovernmentAccountSubtype.AGENCY_ADMIN);
            break;
          }
        }
        return account_subtype;
      }
      default: return[]
    }
  }

  universalSelector(controlName:any,selection:any){
    switch(controlName){
      case 'govsubtype':{
        if(this.chosenMember.government)
            if(this.chosenMember.credentials.account_subtype === selection) return true;
        break;
      }
      case 'role':{
        if(this.chosenMember.government)
          if(this.chosenMember.credentials.role === selection) return true;
      }
    }
    return false
  }

  universalUpdater(controlName:any, value:any){
    switch(controlName){
      case 'govsubtype':{
        if(this.agencyForm){
          this.agencyForm.patchValue({
              account_subtype: value,
          })
        }
        break;
      }
      case 'role' :{
        if(this.agencyForm){
          this.agencyForm.patchValue({
             role : value
          })
        }
      }
    }
  }

  updateToggle(editName:any, btn:any){
    if(editName === 'agency'){
      if(btn === 'cancel'){
          this.patchValue('agency')
          this.agencyEditForm = true
      }else{
          this.agencyEditForm = false
      }
    } 
  }

  //does nothing if no validators
  validateField(editName:any){
    if(editName === 'agency'){
      if (!this.agencyForm.valid) {
        this.agencyForm.markAsTouched();
        for (const control in this.agencyForm.controls) {
          if (this.agencyForm.controls.hasOwnProperty(control)) {
            this.agencyForm.controls[control].markAllAsTouched();
            this.agencyForm.controls[control].markAsDirty();
          }
        }
      }
    }
  }

  openRegisterMemberModal(){
    this.dialogRef.open(AgencyRegisterMemberModalComponent,{
      width: '100vw',
      height: '85vh',
      disableClose: true,
      position:{
        top:'120px',
      },
      data:{
        agency:this.currentAgency,
        user:this.currentUser,
      }
    })
  }
}
